import {
  createStylesParams,
  StyleParamType,
} from 'yoshi-flow-editor-runtime/tpa-settings';

type IStylesParams = {
  sortBy: StyleParamType.Number;
  albumClick: StyleParamType.Number;
  lastThumbnailSize: StyleParamType.Number;
  galleryLayout: StyleParamType.Number;
  gridStyle: StyleParamType.Number;
  overlayAnimation: StyleParamType.Number;
  titlePlacement: StyleParamType.Number;
  galleryHorizontalAlign: StyleParamType.Number;
  thumbnailSize: StyleParamType.Number;
  imageMargin: StyleParamType.Number;
  isProGalleryGoG: StyleParamType.Boolean;
  allowDescription: StyleParamType.Boolean;
  itemOpacity: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  sortBy: {
    type: StyleParamType.Number,
  },
  albumClick: {
    type: StyleParamType.Number,
  },
  lastThumbnailSize: {
    type: StyleParamType.Number,
  },
  galleryLayout: {
    type: StyleParamType.Number,
  },
  gridStyle: {
    type: StyleParamType.Number,
  },
  overlayAnimation: {
    type: StyleParamType.Number,
  },
  titlePlacement: {
    type: StyleParamType.Number,
  },
  galleryHorizontalAlign: {
    type: StyleParamType.Number,
  },
  thumbnailSize: {
    type: StyleParamType.Number,
  },
  imageMargin: {
    type: StyleParamType.Number,
  },
  isProGalleryGoG: {
    type: StyleParamType.Boolean,
  },
  allowDescription: {
    type: StyleParamType.Boolean,
  },
  itemOpacity: {
    type: StyleParamType.Color,
  },
});
