import React, { Component, RefObject } from 'react';
import './AlbumInfo.global.scss';
import { getTextsObject } from '../../../../services/gogHelper';

type FontStyle = { font?: string; fontSize?: string };

export default class AlbumInfo extends Component<AlbumInfoProps> {
  ref: RefObject<HTMLDivElement>;
  constructor(props: AlbumInfoProps) {
    super(props);
    this.ref = React.createRef();
    this.showLayout1Hover = this.showLayout1Hover.bind(this);
  }
  componentDidMount() {
    this.props.styleParams.forceHover && setTimeout(this.showLayout1Hover, 200);
  }

  componentDidUpdate(prevProps: AlbumInfoProps) {
    if (this.props.styleParams.layout !== prevProps.styleParams.layout) {
      if (this.props.styleParams.layout === 1) {
        setTimeout(this.showLayout1Hover, 200);
      }
      if (prevProps.styleParams.layout === 1 && this.ref.current) {
        this.ref.current.style.bottom = '';
      }
    }
  }

  showLayout1Hover() {
    if (this.props.styleParams.layout === 1 && this.ref.current) {
      this.ref.current.style.bottom = '0';
    }
  }

  createDetailsInner(
    texts: TextsObject,
    album: GogAlbum,
    layout: number,
    translate: any,
  ) {
    let detailsInner;
    if (texts) {
      const titleStyle: FontStyle = {};
      if (texts.title) {
        titleStyle.font = texts.title;
      }
      titleStyle.fontSize = texts.titleSize;

      const dateStyle: FontStyle = {},
        countStyle: FontStyle = {};
      if (texts.date) {
        dateStyle.font = texts.date;
        dateStyle.fontSize = texts.dateSize;
        countStyle.font = texts.date; // same as dat
        countStyle.fontSize = texts.countSize;
      }

      let title, date;
      if (album.editables.title) {
        title = (
          <div className="title" style={titleStyle}>
            {album.editables.title}
          </div>
        );
      } else {
        title = (
          <div className="title" style={titleStyle}>
            &nbsp;
          </div>
        );
      }

      if (album.editables.date) {
        date = (
          <div className="date" style={dateStyle}>
            {album.editables.date}&nbsp;
          </div>
        );
      } else {
        date = (
          <div className="date" style={dateStyle}>
            &nbsp;
          </div>
        );
      }

      if (layout === 4) {
        const photosCount = (
          <div className="photos-count" style={dateStyle}>
            {album.photosCount}{' '}
            {(translate &&
              translate('Dashboard_Main_Album_Details_NumPhotos')) ||
              ' photos'}
          </div>
        );
        detailsInner = [date, title, photosCount];
      } else {
        detailsInner = [title, date];
      }
    } else {
      detailsInner = <div></div>;
    }

    return detailsInner;
  }

  render() {
    const texts = getTextsObject(
      this.props.dimensions.width,
      this.props.textPresets,
      this.props.styleParams,
    ) as TextsObject;

    const translate = typeof this.props.t === 'function' && this.props.t;
    const layout = this.props.styleParams.layout;

    const album = this.props.data;

    const detailsInner = this.createDetailsInner(
      texts,
      album,
      layout,
      translate,
    );

    const borderRgba = 'black';

    const textAlign = this.props.styleParams.textAlignment || 'center';

    const albumStyle = {
      width: this.props.dimensions.width + 'px',
      marginRight: '',
      marginTop: '',
      borderWidth: '1px',
      borderColor: borderRgba,
      height:
        layout === 1 || layout === 4 ? this.props.dimensions.width + 'px' : '',
      position: layout === 4 ? 'absolute' : undefined,
      bottom: layout === 4 ? '0' : undefined,
    } as React.CSSProperties;

    if (layout === 1 || layout === 4) {
      albumStyle.height = albumStyle.width;
    }

    const albumClassName = ['album', 'layout' + layout];
    if (texts) {
      const textAlbumClass = texts.albumClass || '';
      albumClassName.push(textAlbumClass);
    }
    const extIcon =
      'albums-svg-font-icons-' +
      (album.settings && album.settings.isPublic === false
        ? 'lock'
        : 'external');

    return (
      <div className={albumClassName.join(' ')} style={albumStyle}>
        {layout === 4 && (
          <div
            className="overlay"
            style={{ opacity: this.props.styleParams.forceHover ? '1' : '' }}
          >
            <div className="mask"></div>
            <i className={extIcon}></i>
          </div>
        )}
        <div ref={this.ref} className={'details-container'}>
          <div
            className={'details' + (!texts ? ' hide' : '')}
            style={{ textAlign }}
          >
            {detailsInner}
          </div>
        </div>
      </div>
    );
  }
}
