import React from 'react';
import { InjectedTranslateProps } from 'react-i18next';

import Gog from './GogOOI/Gog';
import { parseAlbumsCovers } from '../../../services/gogHelper';
import {
  getSortedAlbums,
  orderIndexSorter,
} from '../../../services/albumsSorter';

type GogWrapperProps = InjectedTranslateProps & WidgetProps;

export class GogWrapper extends React.Component<
  GogWrapperProps,
  GogWrapperState
> {
  isSortAlbumsEnabled: boolean;

  constructor(props: GogWrapperProps) {
    super(props);
    this.isSortAlbumsEnabled =
      this.props.isExperimentEnabled &&
      this.props.isExperimentEnabled('specs.albums.sortGogAlbums') || false;
    this.state = {
      gogData: props.gogData,
      albumsCovers: this.isSortAlbumsEnabled
        ? getSortedAlbums(
          props.host.style,
          props.albumsCovers,
          props.coverIdToAlbum,
        )
        : props.albumsCovers.sort(orderIndexSorter),
      coverIdToAlbum: props.coverIdToAlbum,
    };
    this.handleWixEvent = this.handleWixEvent.bind(this);
  }

  async handleWixEvent(event: any) {
    if (event.action === 'albumsData') {
      try {
        const gogAlbums: GogAlbum[] = event.data;
        const { albumsCovers, coverIdToAlbum } = parseAlbumsCovers(gogAlbums);
        this.setState({
          gogData: { ...this.state.gogData, albums: gogAlbums },
          albumsCovers: getSortedAlbums(
            this.props.host.style,
            albumsCovers,
            coverIdToAlbum,
          ),
          coverIdToAlbum,
        });
      } catch (e) {
        console.error('Failed to update gog ' + e);
      }
    }
  }

  componentDidMount() {
    if (this.props.viewMode === 'Editor') {
      this.props.Wix &&
        this.props.Wix.addEventListener(
          this.props.Wix.Events.SETTINGS_UPDATED,
          this.handleWixEvent,
        );
    }
  }

  render() {
    const { t, host, ...rest } = this.props;

    const styleParams = this.props.host.style.styleParams;

    const gogProps = {
      ...this.props,
      pageId: this.props.host.pageId,
      compId: this.props.host.id,
      dimensions: this.props.host.dimensions,
      styleParams,
      textPresets: this.props.host.style.siteTextPresets,
      gogData: this.state.gogData,
      albumsCovers: this.state.albumsCovers,
      coverIdToAlbum: this.state.coverIdToAlbum,
      formFactor: this.props.host.formFactor,
      isExperimentEnabled: this.props.isExperimentEnabled,
      isSSR: this.props.isSSR,
    };

    return (
      <div className="albums-gog-wrapper" data-hook="AlbumsGOGOOI-wrapper">
        <Gog {...gogProps} />
      </div>
    );
  }
}

export default GogWrapper;
